<template>
  <form>
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-primary">
        <div class="card-icon">
          <md-icon>monetization_on</md-icon>
        </div>
        <h4 class="title">Edit Customer</h4>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-40 md-small-size-100">
            <div class="picture-container">
              <div class="picture">
                <div v-if="!image">
                  <img :src="logo" class="picture-src" title="">
                </div>
                <div v-else>
                  <img :src="image" />
                </div>
                <input type="file" @change="onFileChange">
              </div>
              <h6 class="description">Choose Logo</h6>
            </div>
          </div>
          <div class="md-layout-item md-size-50 mt-4 md-small-size-100">
            <md-field :class="[
              'customer-name-field',
              {'md-valid': !errors.has('name') && touched.name},
              {'md-error': errors.has('name')}]">
              <label>Name</label>
              <md-input
                v-model="name"
                data-vv-name="name"
                type="name"
                required
                v-validate="modelValidations.name">
              </md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('name') && touched.name">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('name') && touched.name">done</md-icon>
              </slide-y-down-transition>
            </md-field>            
            <div class="form-category"><small>* Required fields</small></div>            
          </div>
        </div>
      </md-card-content>
      <md-card-actions md-alignment="right">
        <md-button native-type="submit" @click.native.prevent="validate" class="md-success"><md-icon>save</md-icon>Save</md-button>
      </md-card-actions>
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'

export default {
  components: {
    SlideYDownTransition
  },
  props: {
    customerData: {
      type: Object
    }
  },
  data() {
    return {
      image: '',
      name: this.customerData.name,
      logo: this.customerData.logo,
      touched: {
        name: false
      },
      modelValidations: {
        name: {
          required: true,
        }
      }
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then(isValid => {
        var customerModel = {
          name: this.name,
          image: this.image
        }
        this.$emit('on-submit', customerModel, isValid)
      })
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
      return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var reader = new FileReader();
      var vm = this;
      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  },
  watch: {
    name() {
      this.touched.name = true
    },
    customerData() {
        this.name = this.customerData.name
        this.logo = this.customerData.logo
      }
  }
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions{
  border: none;
}
.picture-container{
  position: relative;
  cursor: pointer;
  text-align: center;
  margin-top: 4em;

  .description{
    margin: 0;
    margin-bottom: .5rem;
  }
}
.picture {
  width: 106px;
  height: 106px;
  background-color: #999999;
  border: 4px solid #CCCCCC;
  color: #FFFFFF;
  border-radius: 50%;
  margin: 5px auto;
  overflow: hidden;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;

  &:hover{
    border-color: #4caf50;
  }
}
.picture input[type="file"] {
  cursor: pointer;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0 !important;
  position: absolute;
  top: 0;
  width: 100%;
}
.picture div {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.picture div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.customer-name-field {
  margin-top: 50px;
}
</style>
